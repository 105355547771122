.hero2 {
  user-select: none;

  .hero2-title {
    font-size: 60px;
    font-weight: 300;
    margin-left: -3px;
  }

  .hero-subtitle {
    font-size: 24px;
    font-weight: 200;
    opacity: 0.8;
    width: 75%;
    color: #333333;
    //  padding: 0px 0px 0px 4px !important;
    //padding-left: 4px;
  }
  .small {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    .hero2-title {
      font-size: 50px;
    }
    .hero-subtitle {
      width: 80%;
      font-size: 18px;
    }
  }
}
