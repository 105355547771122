.TypingText {
  width: 100%;
  min-height: 75vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Typewriter {
    font-size:  50px;
  }
}
