.FundCommitmentProgressBar {

  .title {
    //text-transform: uppercase;
    opacity: 0.8;
    font-weight: 300;
    font-size: 16px;

  }

  .subtitle {
    font-weight: 200;
    font-size: 18px;
  }

  .status {
    font-size: 18px;
    font-weight: 300;
  }

  .information {
    opacity: 0.7;
    font-weight: 200;
    font-size: 12px;
  }

  .raised {
    font-size: 18px;
    opacity: 0.8;
    font-weight: 200;
  }

  .total {
    color: #808a9f;
    font-size: 14px;
    opacity: 0.8;
    font-weight: 300;
  }

  .col {
    padding-bottom: 20px;
  }
}