.FundProgressBar {
  .title {
    font-size: 14px !important;
    font-weight: 300;
    opacity: 0.8;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 14px !important;
    font-weight: 200;
  }

  .status {
    font-size: 18px;
    font-weight: 300;
  }

  .raised {
    font-size: 18px;
    opacity: 0.8;
    font-weight: 200;
  }

  .total {
    color: #808a9f;
    font-size: 14px;
    opacity: 0.8;
    font-weight: 300;
  }

  .premier {

    .title,
    .subtitle {
      color: #fff !important;
    }
  }
}