/* Basic editor styles */

.form-fields {

  .ProseMirror {
    flex: 1 1 0%;
    padding: 3px 5px !important;
    font-size: 1em !important;
    margin-left: 25px !important;
    border: 1px solid rgb(204, 204, 204) !important;
    border-radius: 3px !important;

  }
}


.ProseMirror {

  text-align: initial;


  img {
    max-height: 400px !important;
  }

  .btn {
    font-size: 12px !important;
    margin: 2px;
    //padding: 6px !important;
  }

  padding-top: 20px;
  margin: 0rem 0;

  >*+* {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  /* Table-specific styling */

  .tableWrapper {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }


  table {
    width: unset !important;
  }

  @media (max-width: 900px) {
    table {
      min-width: 100%;

      thead {
        display: none;
      }

      tr {
        margin-bottom: 1rem;
        display: block;
        border: 0px solid #ced4da;
      }

      td {
        display: block;
        text-align: left;
        padding: 8px 12px;
        min-height: 30px;

        &:before {
          content: attr(data-label);
          font-weight: bold;
          display: inline-block;
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    margin: 0;
    overflow: hidden;

    &[style*="min-width"] {
      min-width: unset !important;
      width: 100% !important;
    }

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 8px 12px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      word-break: break-word;

      @media (max-width: 768px) {
        min-width: 100%;
        padding: 8px;
      }

      >* {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
}

// // Remove or override the default center alignment
.dbcHlQ h1,
.dbcHlQ h2,
.dbcHlQ h3,
.dbcHlQ h4,
.dbcHlQ h5,
.dbcHlQ h6,
.dbcHlQ a,
.dbcHlQ p,
.dbcHlQ blockquote,
.dbcHlQ ul,
.dbcHlQ ol,
.dbcHlQ li,
.dbcHlQ table {
  text-align: initial; // or text-align: inherit;
}

.tiptap img {
  @media (min-width: 768px) {
    max-height: 380px;
    /* Slightly smaller than container to account for padding */
    width: auto;
    object-fit: contain;
  }
}

.tiptap {


  @media (max-width: 899px) {

    // Hide colgroup on mobile as it's not needed
    colgroup {
      display: none;
    }

    table {
      font-size: 14px;
    }
  }
}

// Add specific styling for tables with no borders if needed
.ProseMirror .noBorder {

  table,
  td,
  th {
    border: none;
    padding: 4px 8px;
  }
}