@import '../variables.scss';

.DDReport {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  .cardName {
    text-align: center;
    //    color: white;
  }

  .nav {
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .nav-item {
    font-weight: 500;
    font-size: 14px;


  }

  h4 {
    color: $dark-grey !important;
  }

  .custom-checkbox {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid $brand-color;
    background-color: white;
    border-radius: 0;
    outline: none;
    cursor: pointer;
    display: block;
    top: 2px;
    transition: background-color 0.2s ease-in-out;
  }

  .custom-checkbox:checked {
    background-color: white;
  }

  .custom-checkbox:checked::before,
  .custom-checkbox:checked::after {
    content: '';
    position: absolute;
    background-color: $brand-color;
    transition: transform 0.2s ease-in-out;
  }

  .custom-checkbox:checked::before {
    width: 2px;
    height: 5px;
    transform: translate(2.5px, 6px) rotate(-45deg);
  }

  .custom-checkbox:checked::after {
    width: 13px;
    height: 2px;
    transform: translate(2.5px, 6px) rotate(-55deg);
  }

  .custom-checkbox:not(:checked)::before,
  .custom-checkbox:not(:checked)::after {
    content: '';
    position: absolute;
    background-color: transparent;
    transition: transform 0.2s ease-in-out;
  }

  .custom-checkbox:not(:checked)::before {
    width: 2px;
    height: 5px;
    transform: translate(2.5px, 6px) rotate(0deg);
  }

  .custom-checkbox:not(:checked)::after {
    width: 13px;
    height: 2px;
    transform: translate(2.5px, 6px) rotate(0deg);
  }
}