@import "../variables.scss";

.team-members {

  font-family: "Lexend";
  font-size: 16px;
  line-height: 20px;
  font-weight: 200;
  justify-content: "center";
  padding: 0px;
}

h1 {
  font-size: 24px;
  font-weight: 300;
  margin: 0px;
  padding-bottom: 16px;
}

row {
  font-weight: 300
};

.notes {
  font-size: 12px;
  font-weight: 200;
  padding: 0.2rem;
  line-height: 16px;
  opacity: 0.8;
}

.error-message {
  color: #EE5235;

  font-size: 12px;
  font-weight: 200;
  padding: 0.2rem;
  line-height: 16px;
  opacity: 0.8;
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
}

.popover {
  width: 300px;
  text-wrap: none;
  border-top: #ccc 1px solid; 
  border-bottom: #ccc 1px solid; 
  border-left: #ccc 1px solid; 
  border-right: #ccc 1px solid; 
}

.popover-small {
  width: 100px;
}

.popover-message {
  font-size: 12px;
  text-align: left;
  font-weight: 200 !important;
  background: transparent !important;
  color: #333333 !important;
  opacity: 0.8;
}