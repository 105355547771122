.AppliedFundListing {

  margin-bottom: 5px;
  margin-left: 25px;
  margin-right: 25px;

  .rdt_TableCell {
    font-size: 14px;

    color: #333333D0 !important;

  }

  .TierBadge {
    font-size: 10px;
  }


  .AppliedFundListingTable {
    font-family: "Lexend";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    justify-content: center;
  }

  .rdt_TableCol_Sortable {
    color: #333333;
  }

  .header {
    border: none !important;
    background: rgba(0, 0, 0, 0.05);
  }

  .title {
    font-size: 24px;
    line-height: 42px;
    font-weight: 300;
    padding: 0px;
    margin-bottom: 5px;

  }

  .subtitle {
    font-size: 12px;
    color: #333333;
    opacity: 0.5;
    font-weight: 300;
  }

  .fundDetails {
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    color: #333333df;
  }

  .fundName {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding-bottom: 5px;
    min-width: 240px;
    color: #333333;

  }

  .PostInvestmentHeader {
    color: #AAACB6;
    align-items: flex-end;
  }

  .PostInvestmentRow {
    color: #333333CC;


  }

  .decision-pending-text {

    margin-left: 10px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    // align-items: flex-end;
    // justify-content: flex-end;

    .decision-pending-item {
      display: inline-block;
    }

    .separator {
      display: none;
    }



    @media (min-width: 768px) {
      flex-wrap: nowrap;
      flex-direction: row;

      .separator {
        display: inline-block;
        margin: 0 0.5rem;
      }
    }
  }

  .rdt_TableCell>div[data-tag="allowRowEvents"] {
    width: 100% !important;
  }
}