.premier-funds-container {
  color: white;
  padding-top: 0vh;
  transition: all 0.3s ease-in-out;

  font-weight: 300;
  padding-left: 0 !important;
  padding-right: 0 !important;

  p {
    margin-bottom: 0 !important;
    font-weight: 300;

  }

  span {
    font-weight: 300;
    font-size: 1.5rem;
  }

  .TierBadge {
    font-weight: unset;
    font-size: 14px;
  }

  .on-waitlist {
    color: white;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }

  .on-waitlist-bold {
    color: white;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 32px;
  }

  @media (max-width: 991px) {

    .fund-image-col {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px; // Increased height for more coverage
        background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.95) 100%);
        pointer-events: none;
      }
    }

    .fund-content-col {
      margin-top: -1rem;
    }
  }

  .fund-card {
    margin-bottom: 3rem;
    font-family: Lexend;
    padding-left: 0 !important;
    padding-right: 0 !important;


    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }

    h1 {
      font-size: 48px;
      font-weight: 300;
      line-height: 70px;
      padding-bottom: 0.5rem;
      margin-left: -3px !important;
    }

    p,
    li {
      font-size: 16px;
      font-weight: 200;
      line-height: 26px;
    }

    .fund-logo {
      width: 150px !important;
      position: absolute;
      top: 1rem;
      left: 2rem;
      object-fit: cover;
    }

    .fund-badge {
      position: absolute;
      width: 100px;
      height: 100px;
      top: 2rem;
      right: -2rem;

      padding: 0.5rem 1rem;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #E6AF2E;
      color: #FFFFFF;

      font-size: 14px;
      font-weight: 600;
      text-align: center;

    }

    .fund-content-col {
      padding: 1rem;
    }


  }



  .ProseMirror {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ProseMirror img {
    max-width: unset !important;
    width: 100% !important;
    min-height: 600px !important;
    margin: auto !important;
    object-fit: cover !important;

    transition: all 0.3s ease-in-out;
    filter: brightness(0.8) grayscale(100%);

    &:hover {
      transform: scale(1.05);
      filter: brightness(1) grayscale(100%);
    }
  }

  .ProseMirror video {
    max-width: unset !important;
    margin: auto !important;
    object-fit: cover !important;
  }

  @media (max-width: 1000px) {
    .ProseMirror img {
      transition: all 0.3s ease-in-out;
      min-height: 400px !important;
      filter: grayscale(100%) !important;

      &:hover {
        transform: unset !important;
        filter: grayscale(100%) !important;
      }

    }

    .fund-badge {
      top: unset !important;
      bottom: 1rem !important;
      right: 2rem !important;

    }

  }


}

.premier-modal.modal {
  input {
    background-color: white !important;
    border: 1px solid #808A9F80;
    border-radius: 0;
    color: #333333;
    font-size: 18px;
    padding: 0.5rem;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: var(--primary);
    }

    &::placeholder {
      color: #808A9F;
      opacity: 0.7;
      font-size: 14px;
    }
  }

  .modal-content {
    background: #141819;
    color: white;
    border-radius: 8px;
    border: none;
  }

  .modal-header {
    border: none;
    padding: 1rem 1rem;
    display: flex;
    justify-content: center;

    .modal-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      //padding-top: 2rem;

      // img {

      //   margin: 0 auto; // Centers the image horizontally
      // }
    }

    .btn-close {
      filter: invert(1) brightness(200%); // Makes the X white
      opacity: 1; // Ensures full visibility
      margin-top: -1rem;

      &:hover {
        opacity: 0.75; // Optional: adds a nice hover effect
      }
    }
  }

  .modal-body {
    padding: 0rem 2.5rem;

    h5 {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 1.5rem;
      font-family: Lexend;
      text-align: center;
    }

    label {
      display: block;
      margin-bottom: 0.5rem;
      color: #999;
      font-size: 16px;
    }



    small {
      display: block;
      margin-top: 0.5rem;
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
    }
  }

  .btn {
    font-weight: 300 !important;
    background-color: #265695 !important;
  }

  .cancel-button {
    background-color: #FFFFFF !important;
    color: #333333 !important;
  }

  .text-muted {
    color: #808A9F !important;

    font-size: 28px;
    font-weight: 400;
    line-height: 32px;


  }

  .modal-footer {
    border: none;
    padding: 1rem 1.5rem 1.5rem;
    display: flex;
    justify-content: center;

    button {
      padding: 0.5rem 2rem;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      color: white;
      background-color: var(--primary);
      border: none;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--primary-hover);
      }
    }
  }
}

.fund-content-wrapper {

  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 999px) {
    padding-left: 4rem !important;
    padding-right: 0rem !important;
  }
}

.admin-message {
  color: #808A9F;
  font-weight: 500;
  font-style: italic;
  padding: 8px 0;
  font-size: 0.95em;
}