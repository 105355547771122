.VCFundCarousel {

  .disabled {
    color: #EEEEEE !important;
    cursor: unset !important;
  }

  .arrow-left {
    position: absolute;
    transition: all .5s;
    z-index: 1000;
    border: 0;
    margin-top: -50px;
    left: -10px;
    opacity: 1;
    cursor: pointer;
    color: #33333380;

    &::before {
      content: '<';
      display: block;
      //background-color: red;
      content: " < ";

      z-index: 1000;
      font-size: 30px;
      width: 40px;
      text-align: center;
    }
  }

  .arrow-right {
    position: absolute;
    transition: all .5s;
    z-index: 1000;
    opacity: 1;
    cursor: pointer;
    right: -10px;
    margin-top: -50px;
    color: #33333380;

    &::before {
      display: block;
      //background-color: red;
      content: " > ";

      z-index: 1000;
      font-size: 30px;
      width: 40px;
      text-align: center;

    }

  }


  .react-multi-carousel-dot button {
    border: none !important;
    width: 10px;
    height: 10px;
  }

  .react-multi-carousel-dot--active button {
    background: #33333380;
  }
}