#LPTeamSelector {

  margin-top: 0px;
  padding-left: 1em;

  color: #333333 !important;
  font-weight: 300;

  a {
    color: #333333;
    margin-top: 14px;
  }

  @media (max-width: 768px) {
    margin-top: -10px;
    position: fixed;
    top: 14px;
  }

  * {
    border: none !important
  }

  a:hover {
    border: none !important;
    color: #333333 !important;
    font-weight: 300;
  }

  .nav-link:hover {

    border: none !important
  }

  .team-dropdown {
    width: fit-content;
    margin-left: calc(0px);

  }

  .fixed {
    padding-top: 20px;
    padding-left: 10px;
    user-select: none;
  }

  .myLP {

    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    padding-bottom: 10px;
  }

  .title {
    font-size: 14px;
    padding-left: 0.5em;
  }

  .selected {
    color: #333333 !important;
    font-weight: 300;
  }


}