@import "../variables.scss";

// a {
//   color: $brand-color !important;
// }
.onboarding {
  font-family: "Lexend";
  font-size: 16px;
  line-height: 20px;
  font-weight: 200;
  justify-content: "center";
  padding: 0px;
}

.accordion {
  font-size: 14px;
  font-weight: 300;
  color: #333333 !important;
  opacity: 0.8;
  background-color: #f8f9fa;
  border-color: #cccccc .5px solid;
  
  &::after {
    width: .75rem;
    height: .75rem;
    background-size: .75rem;
    
  }
}

.accordion-button {
  background-color: white !important;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 300;
  color: #333333 !important;
  opacity: 0.8;
  background-size: .75rem;
  
  &::after {
    width: .75rem;
    height: .75rem;
    background-size: .75rem;
  }
  &:focus {
    // border-bottom-color: #cccccc .5px solid !important;
    box-shadow: none !important;
  }
  
  &:not(:focus) {
    border-color: #cccccc .5px solid !important;
    box-shadow: none !important;
  }
}

.accordion-header {
  background-color: #cccccc !important;
}
.accordion-item {
  border-radius: 0 !important;
  border-color: #cccccc .5px solid !important;  
}

.active-accordion-item .accordion-button {
  font-weight: 500 !important;
}
  

.active-link {
  font-weight: 300 !important;
}

.circle-icon {
  style: {color: #c8c9ca}
}

.custom-image {
  max-width: 1000px; 
}

.custom-step-label .MuiStepLabel-label {
  font-family: "Lexend";
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #333333 !important;

  &.Mui-disabled {
    font-weight: 200 !important;
    opacity: 0.5 !important;
  }

  &.Mui-completed {
    font-weight: 300 !important;
    opacity: 0.8 !important;
  }

  &.Mui-active {
    font-weight: 400 !important;
    opacity: 1.0 !important;
  }
}


.number-icon {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #c8c9ca; 
  color: #333333; 
  text-align: center;
  line-height: 36px;
}

.action-icon {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #3E7CB1; 
  color: #ffffff; 
  text-align: center;
  line-height: 36px;
}

.menu-step {
  font-weight: 200;
  opacity: 0.8;
}

.status-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-line {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.status-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1; /* Ensure dot is on top of line */
}

.status-active {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $brand-color; 
}


.status-completed {
  border-left: 3px solid #333333;
  height: 100%;
  margin: 0 16px;
  display: inline-block;
}

.status-in-progress {
  border-left: 3px solid #cccccc;
  height: 100%;
  margin: 0 16px;
  display: inline-block;
  visibility: visible !important;
}

.status-pending {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #333333; 
}

.status {
  font-size: 12px;
  font-weight: 400;
  color: #333333 !important;
  opacity: 0.8;
  text-transform: uppercase;
}

.status-title {
  font-size: 12px;
  font-weight: 300;
  color: #333333 !important;
  opacity: 0.5;
  text-transform: uppercase;
}

.step-title {
  font-size: 18px;
  font-weight: 300;
  color: #333333; 
  opacity: 0.8;
}

.step-body {
  font-size: 14px;
  font-weight: 200;
  color: #333333;
  opacity: 0.8;
  &.a {
    color: $brand-color;
  }
}

.step-message {
  font-family: "Lexend";
  font-size: 12px;
  font-weight: 200;
  color: #333333;
  opacity: 0.8;

}

.step-action-message  {
  font-family: "Lexend";
  font-size: 12px;
  font-weight: 200;
  color: $brand-color !important;
}

.onboarding-title {
  font-size: 30px;
  font-weight: 300;
  color: #333333;
}

.onboarding-body {
  font-size: 16px;
  font-weight: 200;
  color: #333333;
  opacity: 0.8;
}
