@import "variables.scss";
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css";
/* import bootstrap to set changes */
@import "bootstrap/dist/css/bootstrap.min.css";
@import "notions.scss";

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100vw !important;
  height: 100%;
  //overflow-x: hidden !important;
  font-family: "Lexend", sans-serif;
  font-weight: 300 !important;
  scroll-behavior: smooth;
  //overscroll-behavior-y: none;

}

/* make the customizations */
$theme-colors: ("info": teal,
  "primary": $primary,
  "danger": #ff4136,
);

@media (max-width: 767px) {

  html,
  body {
    overflow-x: hidden;
  }

  .hero-title {
    font-size: 40px !important;
    line-height: 60px !important;
  }
}

$navbar-light-color: $primary;
$navbar-dark-color: $primary;

$navbar-light-hover-color: $base-color;
$dropdown-link-active-color: $base-color;

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

#app {
  background-color: white;
  //color: $primary;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 150px;
}

#footer {
  z-index: 1000;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  backdrop-filter: blur(10px);
  background-image: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .7));

  //  background-color: fade-out($base-color, 0.01) !important;
  //border-top: #308685 1px solid;
  a {
    text-decoration: none !important;
    font-weight: 200;
  }

  strong {
    font-weight: 300;
  }
}

#footer.isPremier {
  color: white;
  z-index: 1000;
  //position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  backdrop-filter: blur(10px);
  background-image: linear-gradient(rgba(0, 0, 0, .9), rgba(0, 0, 0, .8));

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mask-image: linear-gradient(to bottom,
        transparent 0%,
        rgba(0, 0, 0, 0.2) 20%,
        rgba(0, 0, 0, 0.4) 40%,
        rgba(0, 0, 0, 0.6) 60%,
        rgba(0, 0, 0, 0.8) 80%,
        black 100%);
    -webkit-mask-image: linear-gradient(to bottom,
        transparent 0%,
        rgba(0, 0, 0, 0.2) 20%,
        rgba(0, 0, 0, 0.4) 40%,
        rgba(0, 0, 0, 0.6) 60%,
        rgba(0, 0, 0, 0.8) 80%,
        black 100%);
    backdrop-filter: blur(20px);
    z-index: -1;
  }

  a {
    color: white;
    text-decoration: none !important;
    font-weight: 200;
  }

  strong {
    color: white;
    font-weight: 300;
  }
}


.page-width {
  max-width: 1100px;
}

.v0-black {
  color: #333333;
}

// .image-ground {
//   padding-top: 200px;
//   padding-bottom: 200px;
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
// }

// @media (max-width: 768px) {
//   .image-ground {
//     background-size: cover;
//     padding-top: 100px;
//     padding-bottom: 100px;
//   }
// }

.nav-container {
  //position: fixed;
  width: 100vw;
  z-index: 100;
  // background-image: linear-gradient(
  //   fade-out($base-color, 1),
  //   fade-out($base-color, 0.8)
  // ) !important;
  background-color: white !important;
  transition: all 0.5s ease;

  //border-bottom: #308685 1px solid;
  //border-top: #308685 1px solid;
  a {
    text-decoration: none;
  }
}

.nav-container.down {
  top: -120px;
  opacity: 0;
}

.nav-container.up {
  top: -0px;
  opacity: 1;
}

.navbar-collapse {
  //background-color: white;
}

.dropdown-item.active,
.dropdown-item:active {
  //color: $base-color !important;
}

body {
  font-family: "Lexend", sans-serif;
}

.brand {
  font-size: larger;
  letter-spacing: 2px;
  border: none !important;
}

.tagline {
  font-style: italic;
}

.navbar-nav .nav-link {
  text-align: center !important;
  padding: 10 20px !important;
}

.avatar {
  width: 200px;
  margin: auto;
}

.avatar img {
  border: solid 6px transparent;
  border-radius: 50%;
}

.flash-title span {
  position: relative;
  overflow: hidden;
  display: block;
  line-height: 2;
}

.flash-title span::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #30868566;
  animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(-101%);
}

.flash-title span::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(0);
}

.flash-title span:nth-of-type(1)::before,
.flash-title span:nth-of-type(1)::after {
  animation-delay: 1s;
}

.flash-title span:nth-of-type(2)::before,
.flash-title span:nth-of-type(2)::after {
  animation-delay: 1.5s;
}

@keyframes a-ltr-after {
  //0% {
  //  transform: translateX(-100%)
  //}
  //100% {
  //  transform: translateX(101%)
  //}
}

@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(200%);
  }
}

.news-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table {
  --bs-table-bg: initial !important;
}

.hero-title {
  font-size: 60px;
  line-height: 72px;
  font-weight: 400;
  font-family: "Lexend";
}

.hero-subtitle {
  font-size: 16px;
  line-height: 33px;
  font-weight: 300;
  font-family: "Lexend";
  color: #333333cc;
}

.primary-link {
  color: $brand-color !important;
  font-weight: 400;
}

.base-button {
  width: fit-content;
  font-weight: 200 !important;
  white-space: nowrap;
  border-radius: 3px !important;
  border-radius: 0;
  border-width: 0;
  padding: 0.75rem;
  min-width: 150px;
}

.primary-button {
  border-radius: 0;
  border-width: 0;

  min-width: 150px;
  background: $brand-color;
  color: white !important;
  //box-shadow: #cccccc 2px 2px 2px;
}




.primary-button:hover {
  background: invert($brand-color, 30%);
}

.premier-button {
  background: #265695 !important;
  color: white !important;
  padding: 0.5rem 1rem;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

.secondary-button {
  background: #ccc !important;
  color: white !important;
  //box-shadow: #cccccc 2px 2px 2px;
}

.ternary-button {
  background: #fff !important;
  color: #333 !important;
  border: #33333355 solid 1px !important;
}

.info-button {
  font-size: 12px;
  padding: 0.2rem;
  font-weight: 400 !important;
  text-transform: uppercase;
  pointer-events: none;
  background: transparent !important;
  color: $brand-color !important;
}

.info-span {
  font-size: 12px;
  padding: 0.2rem;
  font-weight: 400 !important;
  background: transparent !important;
  color: #333333 !important;
  opacity: 0.8;
}

.action-message {
  font-size: 14px;
  padding: 0px;
  font-weight: 300;
  background: transparent !important;
  color: $brand-color !important;
}

.text-bold {
  font-weight: 600;
}

.bar {
  background-color: #3e7cb1 !important;
}

.float {
  animation: floating 3s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.react-confirm-alert {
  background: white;
  width: 95vw;
  max-width: 530px;
  border-radius: 4px;
  //box-shadow: 0 1px 5px #ffffff33;
  padding: 10px;
}

.navbar {
  padding: 0 !important;
}

.navbar {
  .container-fluid {
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    background-image: linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .95));
  }
}


.navbar .nav-link.active {
  border-bottom: $dark-grey 1px solid;
}



.navbar .nav-link:hover {
  border-bottom: $dark-grey 1px solid;
}

.navbar .nav-link {
  border-bottom: transparent 1px solid;
}

#profileDropDown {
  border: none !important;
}

#RFS-StepperContainer {
  font-family: "Lexend";
}

.TierBadge {
  display: flex;
  max-height: 35px;
  width: fit-content;
  font-weight: 200;
  user-select: none;
  background-color: $brand-color !important;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 6px 12px;
  font-size: 12px;
  padding-top: 8px;
}

.Tier1Badge {
  background-color: #e6af2e !important;
}

.PostInvestmentBadge {
  background-color: $brand-color !important;
}

.SmallerBadge {
  font-weight: 200;
  font-size: 12px !important;
  max-height: 25px !important;
}

.LargerBadge {
  letter-spacing: 1.5px;
  padding: 8px 20px;
  font-size: 14px;
}

.MutedBadge {
  background-color: #ccc !important;
}

.ClosedBadge {
  background-color: #FFF !important;
  color: #333333 !important;
  border: #808A9F80 1px solid !important;

}

.nav-tabs .nav-link {
  cursor: pointer;
  color: $brand-color !important;
  padding: 10px 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: $brand-color;
  border-bottom-width: 0px;
}

.nav-tabs a.active {
  color: white !important;
}

.react-numeric-input {
  height: 46px;

  input {
    color: #333333;
    font-weight: 200;
    height: 46px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.react-cookie-law-select-pane label {
  color: black !important;
}

.react-cookie-law-button-wrapper {

  button.react-cookie-law-accept-all-btn,
  button.react-cookie-law-accept-selection-btn {
    border-radius: 3px !important;
    border-width: 0 !important;
    padding: 15px !important;
    min-width: 150px !important;
    background: $brand-color !important;
    color: white !important;
    box-shadow: #cccccc 2px 2px 2px;
  }
}


.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.text-lightly-muted {
  color: #808a9f !important;
}

.text-muted {
  color: #333333 !important;
}

.react-toggle-track-check,
.react-toggle-track-x {
  margin-top: 8px !important;
}

.rhc-settings__content__footer {
  display: inline-flex !important;
}

.text-align-center {
  text-align: center;
}

.navbar .item {
  white-space: nowrap;
}

.navbar-expand-md .navbar-nav .nav-link {
  display: block !important;
}

@media (min-width: 767px) {
  .rhc-banner__content__secondary {
    width: 200px !important;
  }

  .rhc-banner__content__primary {
    width: 200px !important;
  }


}

@media screen and (max-width: 767px) {
  .navbar .item {
    width: 50vw !important;
    margin: auto !important;

    white-space: nowrap;
    font-size: unset !important;
  }
}

@media screen and (max-width: 900px) and (min-width: 599px) {
  .navbar .item {
    //white-space: nowrap;
    font-size: 12px !important;
  }
}

.rhc-banner__content__message {
  max-width: 800px;
}

.fund-category-title {
  font-size: 30px !important;
  font-weight: 400 !important;
}

.fund-category-title-small {
  font-size: 24px !important;
  font-weight: 400 !important;
}

.big-title {
  font-size: 48px;
  font-weight: 400 !important;
  padding: 5px;
}

.fund-category-description {
  color: #333333;
  font-size: 16px !important;
  font-weight: 200 !important;
}

.fund-title {
  font-size: 24px !important;
  font-weight: 300 !important;


  @media screen and (min-width: 760px) {
    margin-top: 28px !important;
  }

  @media screen and (max-width: 760px) {
    margin-top: 0px !important;
  }
}



.text-light {
  font-weight: 200;
}

.text-bold {
  font-weight: 400 !important;
}

.text-oops {
  font-weight: 200;
  font-size: 18;
  opacity: 0.8;
  color: #333;
}

.welcome-title {
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
}

.dropdown-item {
  font-weight: 200;
}

textarea::placeholder,
input::placeholder {
  font-weight: 200 !important;
}

textarea {
  font-weight: 200 !important;
}

.dropdown-menu {
  width: 216px;
  margin-left: calc(-216px / 2 - 50px);
  border: none;
  border-radius: 0px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  box-shadow: 0px 4px 12px -5px rgba(17, 24, 28, 0.28);
  border: 1px solid rgba(245, 246, 247, 1);
}

.react-confirm-alert-body {
  width: unset !important;
}

/*
DataTable

rdt_TableRow
rdt_TableCol
rdt_TableCol_Sortable
rdt_TableCell
rdt_TableHeader
rdt_TableFooter
rdt_TableHead
rdt_TableHeadRow
rdt_TableBody
rdt_ExpanderRow
*/

table {
  .bg-primary {
    background-color: $brand-color !important;
  }

}

.rdt_Table {
  .bg-primary {
    background-color: $brand-color !important;
  }

  .dropdown {
    position: unset !important;
  }

  min-height: 200px;
}

.rdt_TableRow:first-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

// .rdt_TableRow:last-of-type {
//   border-bottom: 0px solid rgba(0, 0, 0, 0.05) !important;
// }


.rdt_TableRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  font-weight: 300 !important;
  word-wrap: break-word;
}

.rdt_ExpanderRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.rdt_TableCell {
  line-height: 20px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.rdt_TableHeader,
.rdt_TableHeadRow {
  background-color: transparent !important; // Applied fund listing table header
  font-family: "Lexend", sans-serif;
  font-weight: 500 !important;
  font-size: 14px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.20) !important;
}

.rdt_TableRow:first-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.20) !important;
}

.nav {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .nav-item {
    font-weight: 400;
    font-size: 14px;


    a {
      color: #333333 !important;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }

      &.active {
        opacity: 0.8;
        border-bottom: 2px solid rgba(62, 124, 177, 1) !important;
      }
    }


  }
}



.event-card {}

.brand-link {
  color: $brand-color;

  &:hover {
    color: $brand-color;
  }
}

.news-page {

  .notion-title {

    opacity: 1;
    font-size: 40px;
    line-height: 33px;
    font-weight: 400;
    padding-top: 50px;

    text-transform: unset;


    padding-bottom: 48px;
  }


}

.cursor-pointer {
  cursor: pointer;
}

.btn.btn-primary[disabled] {
  background-color: #CCCCCC;
}

.btn-primary {
  background-color: $brand-color;
  border: 0px !important;
}

.auth_signatory-badge {
  font-size: 12px;
  font-weight: 300;
  background-color: #eeedd5 !important;
  color: $dark-grey;
  margin: 2px;
}

.investor-badge {
  font-size: 12px;
  font-weight: 300;
  background-color: #cddeee !important;
  color: $dark-grey;
  margin: 2px;
}

.membership_manager-badge {
  font-size: 12px;
  font-weight: 300;
  background-color: #eed5d6 !important;
  color: $dark-grey;
  margin: 2px;
}

.progress-badge {
  font-size: 12px;
  font-weight: 300;
  height: 20px;
  border-radius: 10px !important;
  border: 1px solid $dark-grey;
  background-color: transparent !important;
  color: $dark-grey;
  margin: 2px;
}

.not_started-badge {
  border-color: #eed5d6 !important;
  color: #eed5d6 !important;
}