.UserRegistration {
  min-height: 50vh;
  padding-top: 50px;
  padding-bottom: 50px;

  a {
    text-decoration: underline !important;
  }

  .inner {
    background-color: #ffffff99;
    max-width: 800px;
  }
}
