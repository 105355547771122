.FundSelectionContainer {
  margin-top: 2rem;
  margin: auto;

  //max-width: 1200px;
  //margin: auto;
  .link:hover {
    //text-decoration: underline;
  }

  .selectionContainer {
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .selectionInnerContainer {
    background-color: #f5f6f7;
    padding: 1rem 0rem;
    margin: 0rem;
    flex-direction: row;
    display: flex;
    max-width: 400px;
    min-width: 400px;

    height: 180px;
    //max-width: 600px;
    align-items: center;

    .title {
      margin-top: 5px;
    }

    .subtitle {
      font-size: 12px;
    }

  }


  .icon {
    padding: 5px;
  }

  .tagLine {
    width: 50%;
    padding-top: 2em;
    padding-bottom: 1em;
  }

  .textContainer {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    min-height: 70px;
    text-align: left;
  }

  @media (max-width: 968px) {
    .textContainer {
      padding-top: 0.5rem;
      text-align: center;
    }

    .selectionInnerContainer {
      padding: 0rem;
      margin: 0.5rem;
      background-color: transparent;
      flex-direction: column;
      width: inherit;
      min-width: unset;

      //align-items: center;
      //justify-content: center;
      //max-width: 50vw;
      //padding: 5px 0px;
      //text-align: center;
      //min-height: 250px;
      flex: 1;
    }

    .tagLine {
      //text-align: center;
      white-space: nowrap;
    }

    .title {
      text-align: center !important;
      white-space: nowrap;
      font-size: 12px;
      text-overflow: ellipsis;
      margin-top: 5px;
    }

    .subtitle {
      font-size: 11px;
      // height: 110px;
    }
  }
}