.signup-page {
  padding-top: 100px;
  display: flex;
  min-height: 100vh;
  font-family: "Lexend", sans-serif;
  position: relative;
  .signup-container {
    width: 50%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    background: white;
  }

  .signup-image {
    width: 50%;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  h1 {
    font-size: 30px;
    font-weight: 300;
    color: #333333;
    margin-bottom: 2rem;
  }

  .form-fields {
    display: flex;
    flex-flow: column nowrap;
    line-height: 1.6em;
    margin: 5px 0;
  }

  .form-fields label {
    color: #333333;
    opacity: 0.8;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    font-weight: 200;
    text-align: left;
  }

  .form-fields input {
    flex: 1;
    line-height: 46px;
    padding: 5px 15px;
    font-size: 1em;
    font-weight: 200;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    margin: 20px 0;
    min-width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 200;
    color: white;
    background: #3e7cb1;
    border: 1px solid #3e7cb1;
    border-radius: 3px;
    cursor: pointer;
    transition: opacity 0.2s;
  }

  button:hover {
    opacity: 0.9;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 3rem;
    font-size: 14px;
    color: #666;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .signup-image .footer {
    background: rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 768px) {
    .signup-page {
      flex-direction: column;
    }

    .signup-container,
    .signup-image {
      width: 100%;
    }

    .signup-container {
      padding: 2rem;
      min-height: 50vh;
    }

    .signup-image {
      min-height: 50vh;
      position: relative;
    }

    .footer {
      position: relative;
      padding: 1rem 2rem;
      margin-top: auto;
    }

    .signup-container {
      display: flex;
      flex-direction: column;
    }

    form {
      flex: 1;
      margin-bottom: 2rem;
    }
  }
}
