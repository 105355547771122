.FundAnnoucement {
  height: 300px;
  background: url("/assets/bg.png");
  background-repeat: repeat;
  //filter: grayscale(30%);
  max-width: 1100px !important;
  background-size: 50%;
  animation: animatedBackground 180s linear infinite;

  //background: linear-gradient(#808a9f, #808a9f);
  //background: #808a9f;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: center;

  .cardName {
    text-align: center;
    color: white;
  }

  .logo {
    padding: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    opacity: 0.3;
  }

  @keyframes animatedBackground {
    from {
      background-position: 0% 0%;
    }

    to {
      background-position: 5000px -5000px;
    }
  }
}