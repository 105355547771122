.episode {
  display: grid;
  grid-template-columns: 1fr 3fr;
  position: relative;
  min-height: 50vh;
}

.episode__number {
  font-size: 10vw;
  font-weight: 600;
  padding: 10px 0;
  position: sticky;
  top: 0;
  text-align: center;
  height: calc(10vw + 20px);
  transition: all 0.2s ease-in;
  margin-top: 60%;
}

.episode__content {
  border-top: 2px solid #fff;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 10px;
  padding: 15px 0;
  align-items: center; /* add this line */
}

.episode__content .title {
  font-weight: 600
}

.episode__content .story {
  line-height: 26px;
}

@media (max-width: 600px) {
  .episode__content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .episode__content .story {
    font-size: 15px;
  }
}
