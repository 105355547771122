.PostInvestmentSelector {
  .title {
    color: #333333CC;
    font-size: 16px;
    font-weight: 300;

  }



  .dropdown-menu {
    width: fit-content;
    transform: translateX(-55px);

  }

  .disabled {
    pointer-events: none;
    color: #33333333;
  }
}