.FundTile {
  cursor: pointer;
  height: 250px;
  width: 250px;
  display: flex;

  .TierBadgeContainer {
    z-index: 100;
    margin-top: 20px;
    margin-left: 0px;
    width: fit-content;
    height: fit-content;
  }

  .img {
    z-index: 0;
    flex: 1;
    max-width: 190px;
    height: auto;
    max-height: 190px;
    margin: auto;
    object-fit: contain;

  }

  .lower {
    right: -50px;
    position: relative;
    bottom: -10px;
  }

  .tier6-img {
    flex: 1;

    max-width: 150px !important;
    height: auto;
    max-height: 150px !important;
    margin: auto;
    object-fit: contain;
  }

  .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


}