.lines {
  top: 0;
  left: 0;
  right: 0;
  min-height: 80vh;
  height: 100%;
  width: 100%;
  opacity: 0;
  animation: fade-in 0.75s 1.5s linear forwards;
}

.line {
  position: absolute;
  width: 1px;
  height: 50%;
  top: 25%;
  left: 50%;
  background: rgba(25, 65, 112, 0.1);
  overflow: hidden;
}

.line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgb(25, 65, 112),
    75%,
    rgb(25, 65, 112) 100%
  );
  -webkit-animation: drop 7s 0s infinite;
  animation: drop 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.line:nth-child(1) {
  margin-left: -25%;
}

.line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.line:nth-child(3) {
  margin-left: 25%;
}

.line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

@-webkit-keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

// ## Animation
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
