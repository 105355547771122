.premier-container {





  background-color: black;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  height: 100vh !important;

  animation: fadeIn 3s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  .content-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 20px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    align-items: center;
    grid-template-columns: 1fr 1fr;

  }


  .text-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    z-index: 1;
  }

  .header-content h1 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.02em;
    font-weight: 300;
  }

  .subtitle {
    color: white;
    margin-top: 16px;
    font-family: Lexend;
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }


  .button {
    max-width: 150px;
  }




  .image-grid-container {
    overflow: hidden;
    height: 100vh !important;
    position: relative;
    z-index: 0;
  }

  .image-columns {
    display: flex;
    gap: 20px;
    height: 100%;
  }

  .image-scroll-column {
    flex: 1;
    position: relative;
    will-change: transform;
  }

  .image-wrapper {
    height: 400px;
    margin: 20px 0;
    //object-fit: cover;
    //overflow: hidden;
    //border-radius: 12px;
    // gray scale

  }

  .scroll-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .skeleton-loader {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }

    100% {
      background-position: -200% 0;
    }
  }

  .hover-effect {
    overflow: hidden;
    //border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    }

    img {
      transition: all 0.3s ease-in-out;
      filter: brightness(0.8) grayscale(100%);

      &:hover {
        transform: scale(1.05);
        filter: brightness(1) grayscale(100%);
      }

    }
  }
}


.logo-carousel {
  width: 100vw;
  display: flex;
}

.logo-carousel-container {

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;


  .logo-wrapper {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 200px;
    }
  }

  @media (max-width: 1024px) {
    .logo-wrapper {
      img {
        max-width: 15vw !important;
      }
    }


  }

  @media (min-width: 1024px) {

    .fixed {
      bottom: 50px !important;
      flex-wrap: nowrap;
      position: fixed;
      left: 0;
      right: 0;
    }
  }

  .fixed {
    flex-wrap: nowrap;
    position: fixed;
    bottom: 80px;
    left: 0;
    right: 0;
  }

  .mobile {
    flex-wrap: wrap !important;
    background-color: transparent;

    .logo-wrapper {
      img {
        max-width: 35vw !important;
        max-height: 50px !important;
        object-fit: contain;
        padding: 10px 10px;
      }
    }


    @media (max-width: 1024px) {
      .logo-wrapper {
        img {
          width: 200px !important;
          //max-width: 35vw !important;
          max-height: 50px !important;
          object-fit: contain;
          padding: 10px 10px;
        }
      }
    }
  }
}




@media (max-width: 1024px) {
  .premier-container .image-grid-container {
    //position: fixed !important;
    opacity: 0.5 !important;
    top: 0px !important;
    z-index: 0 !important;
    max-height: 85vh !important;
  }

  .premier-container .grid-container {
    margin-top: 200px !important;
    grid-template-columns: 1fr !important;
  }


}