.VCFundLibrary {
  color: #333333;

  input {
    border-radius: 0px;
  }

  .fundTitle {
    font-size: 18px;
    color: #333333;
    font-weight: 400;
    line-height: 26px;
    margin-top: 5px;
  }
}