@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500&display=swap");
@import "custom";



.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;

  .text-info {
    color: $brand-color !important;
  }

}

.result-block-container .result-block {
  opacity: 1;
}

.multi-select-container {
  flex: 1 1 0%;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  margin-left: 25px !important;
  padding: 3px 5px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
}

.multi-select-container label {
  background: white;
  transition: all 0.2s ease;
}

.multi-select-container label:hover {
  background-color: #f8f9fa;
}

.multi-select-container .form-check-input {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.w-md-editor-toolbar li>button {
  height: unset !important;
}

.dbcHlQ {

  text-align: unset !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: unset !important;
  }
}

.premier-nav-container.logged-in {
  .navbar .container-fluid {
    background-color: black !important;
    background-image: unset !important;
    backdrop-filter: blur(1px) !important;

  }

}

.premier-nav-container {
  .navbar .container-fluid {
    background-color: transparent !important;
    background-image: unset !important;
    backdrop-filter: blur(1px) !important;

  }

  a {
    color: white !important;
  }

  #LPTeamSelector .selector-text {
    color: white !important;
  }

  #LPTeamSelector .dropdown-toggle .nav-link {
    color: white !important;
  }


  .navbar .nav-link.active {
    border-bottom: white 1px solid;
  }

  .dropdown-item a {
    color: black !important;
  }

  .navbar-toggler {
    svg path {
      stroke: white;
    }
  }

  .btn-close {
    background-color: white !important;
    color: white !important;
  }

  .primary-button {

    color: white !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .login-button {
    background-color: transparent !important;
    color: white !important;
    padding: 0rem 0rem;
    margin: 0rem 0rem;
    width: 120px;
  }

}