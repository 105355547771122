@import "variables.scss";

.notion {
  * {
    font-family: 'Lexend';
    color: black;
    font-weight: 300;
  }

  .title {
    display: none !important;
  }


  form {
    pointer-events: none;
  }

  .notion-collection {
    align-self: unset;
    //min-width: 100%;
  }

  .notion-page-no-cover {
    margin-top: 0px !important;
  }


  // venture pulse!!!
  .notion-title {

    font-weight: 300;
    text-transform: uppercase;
    color: #333333 !important;
    padding: 0px;
    margin: 0px;
    opacity: 0.5;
    font-size: 12px;
    //margin-left: 20px;

  }

  .notion-property-title {}

  .notion-page-title-text {
    font-weight: 300;
    text-transform: uppercase;
    color: black !important;
    padding: 0px;
    margin: 0px;

    font-size: 12px;
  }

  .notion-link {
    pointer-events: all;
  }

  .notion-collection-card {
    user-select: none;
    pointer-events: none;
    border-radius: 0px;

    box-shadow: none;
    padding: 0px;

  }

  .notion-page-icon-inline {
    display: none !important;
  }

  .notion-full-width {
    padding-left: 10px;
    padding-right: 10px;
  }



  .notion-collection-card-cover img {
    border-top: 1px solid #33333322;
    border-left: 1px solid #33333322;
    border-right: 1px solid #33333322;
  }

  .notion-collection-card-body {
    margin-top: 30px;
    padding: 5px;

    //margin-left: -10px;
    // padding-left: 15px;
    // padding-right: 15px;

    .notion-link {
      color: $light-grey;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

  }

  // category
  .notion-collection-card-body> :nth-child(2) {

    padding: 0px;
    margin: 0px;

    font-size: 12px;
    font-weight: 300;

    >* {
      position: absolute;
      margin-top: -48px;
      background-color: transparent;
    }

    .notion-item-default {
      background-color: transparent;
      padding: 0px;
      margin: 0px;
      color: $light-grey;
    }
  }


  .notion-collection-card-body> :last-child .notion-property-text {
    color: $brand-color !important;
    white-space: normal;
    font-size: unset;
    font-weight: 300;



    * {
      color: $brand-color;
      font-weight: 300;
    }
  }

  .notion-collection-card-body> :last-child .notion-property-text:not(:empty):before {
    content: "@V0: ";
    font-weight: 300;
    //display: block;
    //background-color: #CCC;
  }


  .notion-gallery-grid-size-large {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }


  .notion-gallery-grid {
    border-top: 0px solid var(--fg-color-1);
  }

  .notion-collection-row {
    border-bottom: 0px solid var(--fg-color-0);
  }

}

.notion-page-scroller {
  min-height: unset !important;
}

.notion-app {
  min-height: unset !important;
}

.notion-full-page {
  padding-bottom: unset !important;
}

.notion-collection-row {
  border-bottom: 0px solid var(--fg-color-0);
  padding: 0px;
  margin-bottom: 0em;

}

.notion-gallery-grid {
  gap: 25px;
}

// remove the url, and append to the top
.notion-property-url {
  form {
    position: absolute;
    margin-top: -25px;
    margin-left: 0px;

    height: 0px;

    * {
      letter-spacing: 20px;
      //color: red !important;
      color: transparent !important;
    }
  }

  // form::before {
  //   content: "🔗";
  //   font-size: larger;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;

  // }
}