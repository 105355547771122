@import "../variables.scss";


.dropdown-menu {
  width: 300px !important;
}

.note-content {
  font-size: 13px;
  font-weight: 200;
  padding-left: 3vw;
  color: #333333;
  opacity: 0.8;
  borderBottom: '1px solid #ccc'
}

.note-title {
  font-size: 13px;
  font-weight: 300;
  padding-left: 2vw;
  opacity: 0.8;
}

.note-timestamp {
  font-size: 13px;
  font-weight: 200;
  padding-left: 3vw;
  color: #333333;
  opacity: 0.5;
}
.note-type {
  font-size: 13px;
  font-weight: 300;
  padding-left: 2vw;
  color: #333333;
  opacity: 0.8;
}

