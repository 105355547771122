.FundBadge {
  border: 1px solid rgba($color: #000000, $alpha: 0.05);

  .img {
    width: 60px;
    min-width: 60px !important;
    height: auto;
    max-height: 60px;
    margin: auto;
    object-fit: cover;
  }
}

.LargeFundBadge {
  border: 1px solid rgba($color: #000000, $alpha: 0.05);

  .img {
    width: 140px;
    min-width: 140px !important;
    height: auto;
    max-height: 120px;
    margin: auto;
    object-fit: cover;

  }

}