//
//video {
//  width: 100vw;
//  height: 100vh;
//  object-fit: cover;
//  position: absolute;
//  z-index: -1;
//
//
//}
//
//@media (max-width: 768px) {
//  video {
//    display: none;
//  }
//
//}
//
//.main {
//  width: 100vw;
//  height: 100vh;
//}
//
//.content {
//  width: 100vw;
//  height: 100vh;
//  top: 0;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  color: white;
//}
//
//


.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;


  h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
  }

  div {
    font-size: 1.5rem;
    margin: 0;
  }
}
