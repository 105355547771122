.FundReport {
  max-width: 1100px !important;
  color: #333333;
  .Columns {
    ul,
    ol {
      color: #333333;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      list-style-type: none;
    }
  }

  h1 {
    font-weight: 400;
  }
  h2 {
    font-weight: 400;
  }
  h3 {
    font-weight: 300;
  }
  h4 {
    font-weight: 200;
  }
}
