.navbar {
    @media (max-width: 768px) {
        .brand {
            z-index: 1;
        }

        .navbar-toggler {
            z-index: 1;
        }
    }
}